import React from 'react';
import { GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { Button } from 'components/Button';
import { useTypeSafeTranslation } from 'hooks';

const NotFoundPage = () => {
  const { t } = useTypeSafeTranslation();
  return (
    <div className="page_notfound_container">
      <h1>404 - {t('common.404')}</h1>
      <Link href="/" passHref>
        <Button type="turqoise">{t('common.go_to_home')}</Button>
      </Link>
    </div>
  );
};

export default NotFoundPage;

export async function getStaticProps(ctx: GetStaticPropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale as string)),
    },
  };
}
